<template>
  <div class="remind">
    <div class="momey-total" style="font-size: 16px;font-weight: bold;">
      <div style="width:95%;display: flex;justify-content: start;"><div class="item">姓名:111</div>
      <div class="item">性别:111</div>
      <div class="item">年龄:111</div>
      <div class="item item1">诊疗卡号:111</div></div>
      <el-button  class="color-grey" v-print="print">
        <i class="fa fa-print"></i>
        打印
      </el-button>
    </div>
    <div class="gride">
      <div class="back">
        2023
      </div>
      <div class="back">
        第一季度
      </div>
      <div class="back">
        第二季度
      </div>
      <div class="back">
        第三季度
      </div>
      <div class="back backRight">
        第四季度
      </div>
      <div class="back1">
        <div>透析</div>
        <div>方案</div>
      </div>
      <div class="back1">
        <div>
          <div><el-input style="width:90px;margin-right: 5px;" v-model="input" placeholder="请输入"></el-input>次<el-input v-model="input" style="width:90px;margin-right: 5px;margin-left: 5px;" placeholder="请输入"></el-input>周</div>
          <div><el-input style="width:100px;margin-right: 5px;" v-model="input" placeholder="请输入"></el-input>h/次      <el-button type="text" size="small" @click="saveValue('','1')">提交</el-button></div>
        </div>
      </div>
      <div class="back1">
        <div>
          <div>x次x周</div>
          <div>xh/次</div>
        </div>
      </div>
      <div class="back1">
        <div>
          <div>x次x周</div>
          <div>xh/次</div>
        </div>
      </div>
      <div class="back1 backRight">
        <div>
          <div>x次x周</div>
          <div>xh/次</div>
        </div>
      </div>
      <div class="back2">
        抗凝方案
      </div>
      <div class="back2">
        <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="速碧林">速碧林 220ml</el-checkbox>
            <el-checkbox label="达肝素">达肝素 30ml</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="back2">
        <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="速碧林">速碧林 220ml</el-checkbox>
            <el-checkbox label="达肝素">达肝素 30ml</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="back2">
        <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="速碧林">速碧林 220ml</el-checkbox>
            <el-checkbox label="达肝素">达肝素 30ml</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="back2 backRight">
        <div>
          <el-checkbox-group v-model="checkList">
            <el-checkbox label="速碧林">速碧林 120ml</el-checkbox>
            <el-checkbox label="达肝素">达肝素 30ml</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="back2">
        凝血指标
      </div>
      <div class="back2">
        <div>
          <div>PT：</div>
          <div>APTT：</div>
          <div>血小板：</div>
        </div>
      </div>
      <div class="back2">
        <div>
          <div>PT：</div>
          <div>APTT：</div>
          <div>血小板：</div>
        </div>
      </div>
      <div class="back2">
        <div>
          <div>PT：</div>
          <div>APTT：</div>
          <div>血小板：</div>
        </div>
      </div>
      <div class="back2 backRight">
        <div>
          <div>PT：</div>
          <div>APTT：</div>
          <div>血小板：</div>
        </div>
      </div>
      <div class="back2">
        贫血指标
      </div>
      <div class="back2">
        <div>
          <div>HB：</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">SF：<el-button type="text" size="small" @click="editInfo('SF1',info.b1)"><i class="txIons modify"></i></el-button></div>
          <div>TSAT：</div>
        </div>
      </div>
      <div class="back2">
        <div>
          <div>HB：</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">SF：<el-button type="text" size="small" @click="editInfo('SF2',info.b1)"><i class="txIons modify"></i></el-button></div>
          <div>TSAT：</div>
        </div>
      </div>
      <div class="back2">
        <div>
          <div>HB：</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">SF：<el-button type="text" size="small" @click="editInfo('SF3',info.b1)"><i class="txIons modify"></i></el-button></div>
          <div>TSAT：</div>
        </div>
      </div>
      <div class="back2 backRight">
        <div>
          <div>HB：</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">SF：<el-button type="text" size="small" @click="editInfo('SF4',info.b1)"><i class="txIons modify"></i></el-button></div>
          <div>TSAT：</div>
        </div>
      </div>
      <div class="back3">
        透析充分性
      </div>
      <div class="back3">
        <div>
          <div>干体重：</div>
          <div>肌酐：</div>
          <div>尿素：</div>
          <div>PCO2：</div>
          <div>URR：(>65%)</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">β微球蛋白：<el-button type="text" size="small" @click="editInfo('b1',info.b1)"><i class="txIons modify"></i></el-button></div>
        </div>
      </div>
      <div class="back3">
        <div>
          <div>干体重：</div>
          <div>肌酐：</div>
          <div>尿素：</div>
          <div>PCO2：</div>
          <div>URR：(>65%)</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">β微球蛋白：<el-button type="text" size="small" @click="editInfo('b2',info.b1)"><i class="txIons modify"></i></el-button></div>
        </div>
      </div>
      <div class="back3">
        <div>
          <div>干体重：</div>
          <div>肌酐：</div>
          <div>尿素：</div>
          <div>PCO2：</div>
          <div>URR：(>65%)</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">β微球蛋白：<el-button type="text" size="small" @click="editInfo('b3',info.b1)"><i class="txIons modify"></i></el-button></div>
        </div>
      </div>
      <div class="back3 backRight">
        <div>
          <div>干体重：</div>
          <div>肌酐：</div>
          <div>尿素：</div>
          <div>PCO2：</div>
          <div>URR：(>65%)</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">β微球蛋白：<el-button type="text" size="small" @click="editInfo('b4',info.b1)"><i class="txIons modify"></i></el-button></div>
        </div>
      </div>
      <div class="back4">
        营养指标
      </div>
      <div class="back4">
        <div>
          <div>白蛋白：</div>
          <div>甘油三酯：</div>
          <div>总胆固醇：</div>
          <div>低密度脂蛋白：</div>
        </div>
      </div>
      <div class="back4">
        <div>
          <div>白蛋白：</div>
          <div>甘油三酯：</div>
          <div>总胆固醇：</div>
          <div>低密度脂蛋白：</div>
        </div>
      </div>
      <div class="back4">
        <div>
          <div>白蛋白：</div>
          <div>甘油三酯：</div>
          <div>总胆固醇：</div>
          <div>低密度脂蛋白：</div>
        </div>
      </div>
      <div class="back4 backRight">
        <div>
          <div>白蛋白：</div>
          <div>甘油三酯：</div>
          <div>总胆固醇：</div>
          <div>低密度脂蛋白：</div>
        </div>
      </div>
      <div class="back4">
        骨、矿物质代谢
      </div>
      <div class="back4">
        <div>
          <div>血钙：</div>
          <div>血磷：</div>
          <div>钙磷乘积：</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">PTH：<el-button type="text" size="small" @click="editInfo('PTH1',info.b1)"><i class="txIons modify"></i></el-button></div>
        </div>
      </div>
      <div class="back4">
        <div>
          <div>血钙：</div>
          <div>血磷：</div>
          <div>钙磷乘积：</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">PTH：<el-button type="text" size="small" @click="editInfo('PTH2',info.b1)"><i class="txIons modify"></i></el-button></div>
        </div>
      </div>
      <div class="back4">
        <div>
          <div>血钙：</div>
          <div>血磷：</div>
          <div>钙磷乘积：</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">PTH：<el-button type="text" size="small" @click="editInfo('PTH3',info.b1)"><i class="txIons modify"></i></el-button></div>
        </div>
      </div>
      <div class="back4 backRight">
        <div>
          <div>血钙：</div>
          <div>血磷：</div>
          <div>钙磷乘积：</div>
          <div style="cursor: pointer;width: 100%;display: flex;justify-content: space-between;">PTH：<el-button type="text" size="small" @click="editInfo('PTH4',info.b1)"><i class="txIons modify"></i></el-button></div>
        </div>
      </div>
      <div class="back1">
        <div>电解质</div>
      </div>
      <div class="back1">
        <div>
          <div>血钾：</div>
          <div>血钠：</div>
        </div>
      </div>
      <div class="back1">
        <div>
          <div>血钾：</div>
          <div>血钠：</div>
        </div>
      </div>
      <div class="back1">
        <div>
          <div>血钾：</div>
          <div>血钠：</div>
        </div>
      </div>
      <div class="back1 backRight">
        <div>
          <div>血钾：</div>
          <div>血钠：</div>
        </div>
      </div>
      <div class="back3">
        其他指标
      </div>
      <div class="back3">
        <div>
          <div>血糖：</div>
          <div>BNP：</div>
          <div>谷丙转氨酶：</div>
          <div>白细胞：</div>
          <div>糖化血红蛋白：</div>
          <div>C反应蛋白：</div>
        </div>
      </div>
      <div class="back3">
        <div>
          <div>血糖：</div>
          <div>BNP：</div>
          <div>谷丙转氨酶：</div>
          <div>白细胞：</div>
          <div>糖化血红蛋白：</div>
          <div>C反应蛋白：</div>
        </div>
      </div>
      <div class="back3">
        <div>
          <div>血糖：</div>
          <div>BNP：</div>
          <div>谷丙转氨酶：</div>
          <div>白细胞：</div>
          <div>糖化血红蛋白：</div>
          <div>C反应蛋白：</div>
        </div>
      </div>
      <div class="back3 backRight">
        <div>
          <div>血糖：</div>
          <div>BNP：</div>
          <div>谷丙转氨酶：</div>
          <div>白细胞：</div>
          <div>糖化血红蛋白：</div>
          <div>C反应蛋白：</div>
        </div>
      </div>
      <div class="back1">
        传染病检查
      </div>
      <div class="back1">
        <div>

        </div>
      </div>
      <div class="back1">
        <div>

        </div>
      </div>
      <div class="back1">
        <div>

        </div>
      </div>
      <div class="back1 backRight">
        <div>

        </div>
      </div>
      <div class="back3">
        存在问题、原因分析及处理方案
      </div>
      <div class="back3">
        <div>

        </div>
      </div>
      <div class="back3">
        <div>

        </div>
      </div>
      <div class="back3">
        <div>

        </div>
      </div>
      <div class="back3 backRight">
        <div>

        </div>
      </div>
    </div>
    <div id="printArea">
      <h2 style="margin: 5px auto;width: 100%;text-align: center;">惠州市中医医院血透患者季度评估表</h2>
      <div class="momey-total" style="font-size: 16px;font-weight: bold;">
        <div class="item">姓名:111</div>
        <div class="item">性别:111</div>
        <div class="item">年龄:111</div>
        <div class="item item1">诊疗卡号:111</div>
      </div>
      <div class="gride">
        <div class="back">
          2023
        </div>
        <div class="back">
          第一季度
        </div>
        <div class="back">
          第二季度
        </div>
        <div class="back">
          第三季度
        </div>
        <div class="back backRight">
          第四季度
        </div>
        <div class="back1">
          <div>透析</div>
          <div>方案</div>
        </div>
        <div class="back1">
          <div>
            <div>x次x周</div>
            <div>xh/次</div>
          </div>
        </div>
        <div class="back1">
          <div>
            <div>x次x周</div>
            <div>xh/次</div>
          </div>
        </div>
        <div class="back1">
          <div>
            <div>x次x周</div>
            <div>xh/次</div>
          </div>
        </div>
        <div class="back1 backRight">
          <div>
            <div>x次x周</div>
            <div>xh/次</div>
          </div>
        </div>
        <div class="back2">
          抗凝方案
        </div>
        <div class="back2">
          <div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="速碧林">速碧林 220ml</el-checkbox>
              <el-checkbox label="达肝素">达肝素 30ml</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="back2">
          <div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="速碧林">速碧林 220ml</el-checkbox>
              <el-checkbox label="达肝素">达肝素 30ml</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="back2">
          <div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="速碧林">速碧林 220ml</el-checkbox>
              <el-checkbox label="达肝素">达肝素 30ml</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="back2 backRight">
          <div>
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="速碧林">速碧林 120ml</el-checkbox>
              <el-checkbox label="达肝素">达肝素 30ml</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="back2">
          凝血指标
        </div>
        <div class="back2">
          <div>
            <div>PT：</div>
            <div>APTT：</div>
            <div>血小板：</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div>PT：</div>
            <div>APTT：</div>
            <div>血小板：</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div>PT：</div>
            <div>APTT：</div>
            <div>血小板：</div>
          </div>
        </div>
        <div class="back2 backRight">
          <div>
            <div>PT：</div>
            <div>APTT：</div>
            <div>血小板：</div>
          </div>
        </div>
        <div class="back2">
          贫血指标
        </div>
        <div class="back2">
          <div>
            <div>HB：</div>
            <div>SF：</div>
            <div>TSAT：</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div>HB：</div>
            <div>SF：</div>
            <div>TSAT：</div>
          </div>
        </div>
        <div class="back2">
          <div>
            <div>HB：</div>
            <div>SF：</div>
            <div>TSAT：</div>
          </div>
        </div>
        <div class="back2 backRight">
          <div>
            <div>HB：</div>
            <div>SF：</div>
            <div>TSAT：</div>
          </div>
        </div>
        <div class="back3">
          透析充分性
        </div>
        <div class="back3">
          <div>
            <div>干体重：</div>
            <div>肌酐：</div>
            <div>尿素：</div>
            <div>PCO2：</div>
            <div>URR：(>65%)</div>
            <div>β微球蛋白：{{info.b1}}</div>
          </div>
        </div>
        <div class="back3">
          <div>
            <div>干体重：</div>
            <div>肌酐：</div>
            <div>尿素：</div>
            <div>PCO2：</div>
            <div>URR：(>65%)</div>
            <div>β微球蛋白：{{info.b2}}</div>
          </div>
        </div>
        <div class="back3">
          <div>
            <div>干体重：</div>
            <div>肌酐：</div>
            <div>尿素：</div>
            <div>PCO2：</div>
            <div>URR：(>65%)</div>
            <div>β微球蛋白：{{info.b3}}</div>
          </div>
        </div>
        <div class="back3 backRight">
          <div>
            <div>干体重：</div>
            <div>肌酐：</div>
            <div>尿素：</div>
            <div>PCO2：</div>
            <div>URR：(>65%)</div>
            <div>β微球蛋白：{{info.b4}}</div>
          </div>
        </div>
        <div class="back4">
          营养指标
        </div>
        <div class="back4">
          <div>
            <div>白蛋白：</div>
            <div>甘油三酯：</div>
            <div>总胆固醇：</div>
            <div>低密度脂蛋白：</div>
          </div>
        </div>
        <div class="back4">
          <div>
            <div>白蛋白：</div>
            <div>甘油三酯：</div>
            <div>总胆固醇：</div>
            <div>低密度脂蛋白：</div>
          </div>
        </div>
        <div class="back4">
          <div>
            <div>白蛋白：</div>
            <div>甘油三酯：</div>
            <div>总胆固醇：</div>
            <div>低密度脂蛋白：</div>
          </div>
        </div>
        <div class="back4 backRight">
          <div>
            <div>白蛋白：</div>
            <div>甘油三酯：</div>
            <div>总胆固醇：</div>
            <div>低密度脂蛋白：</div>
          </div>
        </div>
        <div class="back4">
          骨、矿物质代谢
        </div>
        <div class="back4">
          <div>
            <div>血钙：</div>
            <div>血磷：</div>
            <div>钙磷乘积：</div>
            <div>PTH：</div>
          </div>
        </div>
        <div class="back4">
          <div>
            <div>血钙：</div>
            <div>血磷：</div>
            <div>钙磷乘积：</div>
            <div>PTH：</div>
          </div>
        </div>
        <div class="back4">
          <div>
            <div>血钙：</div>
            <div>血磷：</div>
            <div>钙磷乘积：</div>
            <div>PTH：</div>
          </div>
        </div>
        <div class="back4 backRight">
          <div>
            <div>血钙：</div>
            <div>血磷：</div>
            <div>钙磷乘积：</div>
            <div>PTH：</div>
          </div>
        </div>
        <div class="back1">
          <div>电解质</div>
        </div>
        <div class="back1">
          <div>
            <div>血钾：</div>
            <div>血钠：</div>
          </div>
        </div>
        <div class="back1">
          <div>
            <div>血钾：</div>
            <div>血钠：</div>
          </div>
        </div>
        <div class="back1">
          <div>
            <div>血钾：</div>
            <div>血钠：</div>
          </div>
        </div>
        <div class="back1 backRight">
          <div>
            <div>血钾：</div>
            <div>血钠：</div>
          </div>
        </div>
        <div class="back3">
          其他指标
        </div>
        <div class="back3">
          <div>
            <div>血糖：</div>
            <div>BNP：</div>
            <div>谷丙转氨酶：</div>
            <div>白细胞：</div>
            <div>糖化血红蛋白：</div>
            <div>C反应蛋白：</div>
          </div>
        </div>
        <div class="back3">
          <div>
            <div>血糖：</div>
            <div>BNP：</div>
            <div>谷丙转氨酶：</div>
            <div>白细胞：</div>
            <div>糖化血红蛋白：</div>
            <div>C反应蛋白：</div>
          </div>
        </div>
        <div class="back3">
          <div>
            <div>血糖：</div>
            <div>BNP：</div>
            <div>谷丙转氨酶：</div>
            <div>白细胞：</div>
            <div>糖化血红蛋白：</div>
            <div>C反应蛋白：</div>
          </div>
        </div>
        <div class="back3 backRight">
          <div>
            <div>血糖：</div>
            <div>BNP：</div>
            <div>谷丙转氨酶：</div>
            <div>白细胞：</div>
            <div>糖化血红蛋白：</div>
            <div>C反应蛋白：</div>
          </div>
        </div>
        <div class="back1">
          传染病检查
        </div>
        <div class="back1">
          <div>

          </div>
        </div>
        <div class="back1">
          <div>

          </div>
        </div>
        <div class="back1">
          <div>

          </div>
        </div>
        <div class="back1 backRight">
          <div>

          </div>
        </div>
        <div class="back4">
          存在问题、原因分析及处理方案
        </div>
        <div class="back4">
          <div>

          </div>
        </div>
        <div class="back4">
          <div>

          </div>
        </div>
        <div class="back4">
          <div>

          </div>
        </div>
        <div class="back4 backRight">
          <div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { ref, reactive, toRefs, nextTick } from 'vue'
  import { useRoute } from 'vue-router'
  import service from '@/utils/request'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import BaseDialog from '@/components/Dialog/index.vue'
  export default {
    name: 'remind',
    components: {
      BaseDialog,
    },
    setup() {
      const route = useRoute()
      const state = reactive({
        print: {
          id: 'printArea',
          popTitle: '季度评估',
        },
        searchData: {
          patient_id: route.params.id,
        },
        ruleForm: {},
        tableData: [],
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        info: {
          b1: 11,
          b2: 22,
          b3: 33,
          b4: 44
        },
        input:'',
        modRemind: false,
        tableLoading: false,
        saveLoading: false,
      })
      const ruleFormRef = ref(null)

      const initData = () => {
        console.log(1111, state.info)
        // handleSearch()
      }
      const handleSearch = () => {
        state.pageData.currentPage = 1
        getDataList()
      }
      const handleSizeChange = (val) => {
        state.pageData.pageSize = val
        getDataList()
      }
      const handleCurrentChange = (val) => {
        state.pageData.currentPage = val
        getDataList()
      }
      const getDataList = async () => {
        state.searchData.current = state.pageData.currentPage
        state.searchData.size = state.pageData.pageSize
        state.tableLoading = true
        let res = await service.post(
          '/api/patient_notice/get_notice_list',
          state.searchData
        )
        state.tableLoading = false
        if (res.code === 0) {
          state.tableData = res.data.record
          state.pageData.totalSum = res.data.count
        }
      }
      const addRemind = () => {
        state.modRemind = true
        state.ruleForm = {}
        nextTick(() => {
          ruleFormRef.value.clearValidate()
        })
      }
      const openRemind = (row) => {
        state.modRemind = true
        state.ruleForm = JSON.parse(JSON.stringify(row))
        nextTick(() => {
          ruleFormRef.value.clearValidate()
        })
      }
      const delRemind = (item) => {
        ElMessageBox.confirm('您确认要删除吗?', '操作提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            let res = await service.post('/api/patient_notice/delete_notice', {
              id: item.id,
            })
            if (res.code === 0) {
              ElMessage.success(res.msg)
              getDataList()
            }
          })
          .catch(() => { })
      }

      const saveRemind = () => {
        ruleFormRef.value.validate(async (valid) => {
          if (valid) {
            let url = ''
            state.ruleForm.patient_id = route.params.id
            if (state.ruleForm.id) {
              url = '/api/patient_notice/edit_notice'
            } else {
              url = '/api/patient_notice/create_notice'
            }
            let res = await service.post(url, state.ruleForm)
            if (res.code === 0) {
              ElMessage.success(res.msg)
              getDataList()
              state.modRemind = false
            }
          }
        })
      }
      const editInfo = (type, val) => {
        console.log(type, val)
        ElMessageBox.prompt('您确认要修改吗？', '自定义数值', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
        })
          .then(async (value) => {
            console.log(value.value)
            state.info[type] = value.value
          })
          .catch(() => { })
      }
      const saveValue = (e,code)=>{
        console.log(e,code)
      }
      return {
        ...toRefs(state),
        ruleFormRef,
        handleSizeChange,
        handleCurrentChange,
        addRemind,
        openRemind,
        delRemind,
        saveRemind,
        initData,
        editInfo,
        saveValue
      }
    },
  }
</script>

<style scoped lang="scss">
  .search-top {
    margin-bottom: 12px;
  }

  .schemeBox {
    margin-top: 16px;
  }

  .momey-total {
    display: flex;
    margin: 10px;
    line-height: 30px;

    .item {
      margin-right: 20px;
      width: 150px;
    }
  }

  .gride {
    display: grid;
    grid-template-columns: 12% 22% 22% 22% 22%;
    grid-template-rows: repeat(1, 40px);
    align-items: center;

    ::v-deep .el-checkbox .el-checkbox__label {
      font-size: 16px;
      font-family: "Source Han Sans CN-Regular";
      color: #000;
    }

    .back {
      background-color: #f5f5f5;
      font-size: 18px;
      text-align: left;
      /* padding: 12px; */
      height: 40px;
      line-height: 40px;
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding-left: 8px;
      padding-right: 8px;
    }

    .backRight {
      border-right: 1px solid #ccc;
    }

    .back1,
    .back2,
    .back3,
    .back4 {
      font-size: 15px;
      padding: 5px 0;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      padding-left: 10px;
      div{
        width: 100%;
      }
    }

    .back1 {
      height: 90px;
      line-height: 38px;
      display: flex;
      align-items: center;
    }

    .back2 {
      height: 70px;
      display: flex;
      align-items: center;
    }

    .back3 {
      height: 130px;
      display: flex;
      align-items: center;
    }

    .back4 {
      height: 90px;
      display: flex;
      align-items: center;
    }
  }

  #printArea {
    position: absolute;
    z-index: -1;

    .printTab {}

  }
</style>